import { watch } from 'vue';
import { useRoute } from 'vue-router';

/**
 * Assumption: The resource (aka entity) must have an "id" field (primary key).
 *
 * @param resourceRef the reference to the resource (aka entity).
 * @param resourceIdentifier the identifier of the resource (e.g. entity ID or entity name).
 * @param identifierRef the reference to the resource identifier.
 * @param identifierName the identifier name in route params.
 * @param callable the async operation to call on each change.
 * @param immediate a flag that declares if the 'watch' must be executed immediately.
 *
 * @author Dimitris Gkoulis
 * @createdAt 2021
 * @lastModifiedAt Thursday 30 December 2021
 */
export default function useCommonReactiveRouteResource (resourceRef, resourceIdentifier, identifierRef, identifierName, callable, immediate = true) {
    const route = useRoute();

    const wSource = () => route.params;
    const wCallback = async (newParams) => {
        if (typeof newParams[identifierName] !== 'string') {
            return;
        }

        identifierRef.value = newParams[identifierName];

        if (resourceRef.value === null) {
            await callable();
        } else {
            if (resourceRef.value[resourceIdentifier] !== identifierRef.value) {
                await callable();
            }
        }
    };
    const wOptions = { immediate: immediate };

    watch(wSource, wCallback, wOptions);
}
