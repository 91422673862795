import { watch } from 'vue';
import { useRoute } from 'vue-router';

/**
 * Triggers an actions that loads or reloads a resource
 * when the target route parameter changes.
 *
 * @param entityRef the reference to the entity.
 * @param entityIdentifierRef the reference to the entity ID.
 * @param entityIdentifierName the name of the entity identifier. E.g. id, name, etc.
 * @param parameterName the name of the route param.
 * @param callable action triggered on each change.
 * @param immediate a flag that declares if the 'watch' must be executed immediately.
 *
 * @author Dimitris Gkoulis
 * @createdAt 6 February 2021
 * @lastModifiedAt 8 February 2021
 */
export default function useCommonReactiveResourceBasedOnRouteParam (entityRef, entityIdentifierRef,
    entityIdentifierName, parameterName, callable, immediate = true) {
    const route = useRoute();

    const wSource = () => route.params;
    const wCallback = async (newParams) => {
        if (typeof newParams[parameterName] !== 'string') {
            return;
        }

        entityIdentifierRef.value = newParams[parameterName];

        if (entityRef.value === null) {
            await callable();
        } else {
            if (entityRef.value[entityIdentifierName] !== entityIdentifierRef.value) {
                await callable();
            }
        }
    };
    const wOptions = { immediate: immediate };

    watch(wSource, wCallback, wOptions);
}
